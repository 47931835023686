require("@rails/ujs").start()

import 'lazysizes';

import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import '../javascript/index'

import '../styles.scss'

import '../stylesheets/iconfont_iconfont.scss';

import 'pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min';

require("bootstrap/dist/js/bootstrap")

window.$ = $;
$(() => {

  $('#program').on('hide.bs.collapse', function (e) {
    $('.itinerary').removeClass('itinerary--active');
  });
  $('#program').on('show.bs.collapse', function (e) {
    $(e.target).closest('div.itinerary').addClass('itinerary--active');
  })
  $('#program').on('shown.bs.collapse', function (e) {
    $(e.target).closest('div.itinerary').addClass('itinerary--active');
  })

  $('#program .collapse').collapse();

  if (window.Fancybox) {
    Fancybox.bind('[data-fancybox="gallery"]', {
      animated: false,
    })
  }

  // Homepage filter
  $('#date-from').datetimepicker({
    format: 'DD.MM.YYYY',
  });

  $('#date-to').datetimepicker({
    format: 'DD.MM.YYYY',
    useCurrent: false //Important! See issue #1075
  });

  $("#date-from").on("dp.change", function (e) {
      $('#date-to').data("DateTimePicker").minDate(e.date);
  });

  $("#date-to").on("dp.change", function (e) {
      $('#date-from').data("DateTimePicker").maxDate(e.date);
  });

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // Remove the highlighted class from all nav items
    $(e.target).closest('.list-group').find('.menu-pill__item').removeClass('menu-pill__item--highlighted');

    // Add the highlighted class to the parent nav item of the activated tab
    $(e.target).closest('.menu-pill__item').addClass('menu-pill__item--highlighted');
  });

  $('.request-button').on('click', function() {
    var date = $(this).data('date');

    const customEvent = new CustomEvent('requestDate', { detail: { date } });
    document.dispatchEvent(customEvent);
  });
});
