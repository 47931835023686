$(() => {
  const initSlider = (carousel) => {
    const indicators = carousel.querySelectorAll("[data-carousel-indicators] li")
    const arrows = carousel.querySelectorAll("[data-carousel-arrows] a")
    const itemsWrapper = carousel.querySelector("[data-carousel-slider]")

    if (!carousel || !itemsWrapper || !(indicators || arrows)) {
      return
    }

    const items = itemsWrapper.querySelectorAll("[data-carousel-item")
    const config = {threshold: 0.5};

    let observer = new IntersectionObserver(function (entries, self) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          intersectionHandler(entry);
        }
      });
    }, config);

    items.forEach((item) => {
      observer.observe(item);
    });

    function intersectionHandler(entry) {
      const current = $(entry.target).index()

      indicators.forEach((item, index) => {
        if (index === current) {
          item.classList.add("active")
        } else {
          item.classList.remove("active")
        }
      })
    }

    // indicators click
    $(indicators).on("click", function() {
      const current = $(this).index()

      itemsWrapper.scrollTo({
        left: $(items).eq(current).width() * current,
        top: 0,
        behavior: "smooth"
      })
    })

    $(arrows).on("click", function(event) {
      event.preventDefault()
      const direction = $(this).data("direction")

      sliderShift = $(itemsWrapper).scrollLeft()
      sliderWidth = $(itemsWrapper).width()

      if (direction === 'next') {
        $(items).each((index, item) => {
          if (sliderWidth < $(item).offset().left + $(item).width()) {
            itemsWrapper.scrollTo({
              left: sliderShift + $(item).offset().left - 30,
              top: 0,
              behavior: "smooth"
            })
            return false
          }
        })
      } else {
        $(items).each((index, item) => {
          if ($(item).offset().left > 0 && index !== 0 && $(items).eq(index - 1).offset().left < 0) {
            itemsWrapper.scrollTo({
              left: sliderShift - sliderWidth + $(items).eq(index - 1).offset().left + $(items).eq(index - 1).width() + 30,
              top: 0,
              behavior: "smooth"
            })
            return false
          }
        })
      }
    })
  }

  const carousels = document.querySelectorAll("[data-carousel]");

  if (carousels && carousels.length) {
    carousels.forEach((carousel) => {
      initSlider(carousel)
    })
  }
});
