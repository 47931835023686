$(() => {
  const pageNav = document.querySelector('#page-nav')

  if (!pageNav) {
    return
  }

  const activeClass = "menu-pill__item--active"
  const config = {threshold: 0.5};

  let observer = new IntersectionObserver(function (entries, self) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersectionHandler(entry);
      }
    });
  }, config);

  pageNav.querySelectorAll("a").forEach((link) => {
    const sectionId = link.getAttribute("href")
    const section = document.querySelector(sectionId)
    observer.observe(section);
  });

  function intersectionHandler(entry) {
    const id = entry.target.id;
    const navItem = pageNav.querySelector(`[href='#${id}']`).closest("li")

    if (entry.intersectionRatio > 0) {
      navItem.classList.add(activeClass)
      $(navItem).siblings().removeClass(activeClass)
    }
  }
});
